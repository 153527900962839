@import url('https://fonts.googleapis.com/css?family=Assistant');
@import url('https://fonts.googleapis.com/css?family=Fredoka');
@import url('https://db.onlinewebfonts.com/c/e695a2b548e145591a266fc50eb492e0?family=Regular');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Regular', 'Assistant'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen {
  transition: opacity 1s ease;
  opacity: 0; /* Initially hidden */
}

.screen.visible {
  opacity: 1; /* Visible after translation */
}
